/* eslint-disable max-len */
import React from 'react';
import { Helmet } from 'react-helmet';

// TODO: get metadata
import PropTypes from 'prop-types';

const HppHelmet = (props) => (
    <Helmet>
        <meta charSet="utf-8" />
        <title>{props.title}</title>
        <link rel="icon" type="image/x-icon" href="/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />
        <meta property="og:url" content={props.url} />
    </Helmet>
);

HppHelmet.propTypes = {
    url: PropTypes.string,
    title: PropTypes.string,
    serverURL: PropTypes.string,
};

export default HppHelmet;

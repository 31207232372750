import React, { Component } from 'react';
import { Player } from 'video-react';
import PropTypes from 'prop-types';
import ObjectFit from '../../../../utils/objectFit';
const heroVideo = `${process.env.RAZZLE_ASSET_URL}/media/videos/Homepage-Hero-Desktop.mp4`;
const heroVideoMobile = `${process.env.RAZZLE_ASSET_URL}/media/videos/Homepage-Hero+Mobile.mp4`;



class VideoBackground extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
    }

    state = {
        isLoaded: false,
    };

    componentDidMount() {
        window.addEventListener('load', this.triggerVideoLoad);
    }

    componentDidUpdate() {
        if (this.state.isLoaded) {
            this.adjustVideo();
        }
    }

    triggerVideoLoad = () => {
        this.fakePlayer = document.createElement('video');
        this.fakePlayer.addEventListener('canplay', this.handleVideoLoad);
        this.fakePlayer.src = heroVideo;
    };

    handleVideoLoad = () => {
        this.setState({
            isLoaded: true,
        });
    };

    adjustVideo = () => {
        this.player.current.actions.play();

        // IE object fit support
        const videoBg = document.querySelector('video');

        if (videoBg !== undefined) ObjectFit.cover(videoBg);

        window.addEventListener('resize', function() {
            ObjectFit.cover(videoBg);
        });
    };

    render() {
       
        const { screenData } = this.props;
        return (
            <Player
                src={(screenData.screenData.width >= 1024) ? heroVideo : heroVideoMobile}
                ref={this.player}
                fluid={false}
                autoPlay={true}
                aspectRatio="16:9"
                preload="auto"
                playsInline={true}
                muted={true}
                loop={true}
                className="video just-poster"
            />
        );
    }
}

VideoBackground.propTypes = {
    screenData: PropTypes.object,
};

export default VideoBackground;
/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ParallaxImage from '../../../UI/ImageTreatment/ParallaxImage';
import ImageSmall from '../../../UI/ImageTreatment/ImageSmall';

function buildWrapperClassName(orientation, imageSmall) {
    let className = '';

    if (orientation === 'right' && imageSmall) {
        className = 'is-offset-2-desktop is-10-desktop';
    } else if (orientation === 'right' && !imageSmall) {
        className = 'is-offset-1-desktop is-11-desktop';
    } else if (orientation === 'left' && imageSmall) {
        className = 'is-12-desktop';
    } else {
        className = 'is-12-desktop';
    }

    return className;
}


const CaseStudyImages = ({ orientation, images, isDesktop, index }) => {
    if (!images || images.length === 0) return null;

    const firstImg = images.find(img => img.order === 1) || images[0];
    const secondImg = images.find(img => img.order === 2) || images[1];
    const imageBig = `${process.env.RAZZLE_ASSET_URL}${firstImg.url}`;
    const imageSmall = secondImg ? `${process.env.RAZZLE_ASSET_URL}${secondImg.url}` : null;

    let blue, yellow;

    switch (index) {
        case 0:
            blue = {
                x: 'right',
                size: 'M',
            };

            yellow = {
                x: 'right',
                y: 'bottom',
                orientation: 'horizontal',
            };
            break;
        
        case 1:
            blue = {
                x: 'right',
                size: 'L',
            };

            yellow = {
                x: 'left',
                y: 'bottom',
                orientation: 'horizontal',
            };
            break;
        
        case 2:
            blue = {
                x: 'left',
                size: 'M',
            };

            yellow = {
                x: 'right',
                y: 'bottom',
                orientation: 'vertical',
            };
            break;

        case 3:
            blue = {
                x: 'right',
                size: 'S',
            };

            yellow = {
                x: 'left',
                y: 'bottom',
                orientation: 'vertical',
            };
            break;
    
        default:
            blue = {
                x: 'right',
                size: 'M',
            };

            yellow = {
                x: 'right',
                y: 'bottom',
                orientation: 'horizontal',
            };
            break;
    }
    
    const classSize = index === 0 ? 'is-3-2' : 'is-3-2';

    return (
        <div className="image-container columns font-ff-din-pro color-darkblue">
            {imageBig && (
                <ParallaxImage
                    wrapperClassName={buildWrapperClassName(orientation, imageSmall)}
                    decorators={{
                        blue: blue,
                        yellow: yellow,
                    }}
                    className={classSize}
                    image={imageBig}
                />
            )}
            {imageSmall && isDesktop && (
                <ImageSmall wrapperClassName={'is-7-desktop'} className="is-3-2" image={imageSmall} orientation={orientation} />
            )}
        </div>
    );
};

CaseStudyImages.propTypes = {
    orientation: PropTypes.string,
    images: PropTypes.array,
    isDesktop: PropTypes.bool,
    index: PropTypes.number,
};

export default connect(
    ({ screen }) => ({ isDesktop: screen.isDesktop }),
    null,
)(CaseStudyImages);



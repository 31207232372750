import React from 'react';
import PropTypes from 'prop-types';
import { Parallax } from 'react-scroll-parallax';
import './ParallaxImage.css';

import LazyBg from '../LazyImage/LazyBg';

function decoratorBlueClassName(blue) {
    let className = '';

    if (blue) {
        className = 'svg-triangle-blue';

        if (blue.size === 'S') {
            className = `${className} small`;
        } else if (blue.size === 'M') {
            className = `${className} medium`;
        } else if (blue.size === 'L') {
            className = `${className} large`;
        }
    
        if (blue.x === 'right') {
            className = `${className} right`;
        } else {
            className = `${className} left`;
        }
    }

    return className;
}

function decoratorYellowClassName(yellow) {
    let className = '';

    if (yellow) {
        className = 'svg-yellow-triangle';

        if (yellow.y === 'bottom') {
            if (yellow.x === 'right') {
                className = `${className} bottom-right`;
            } else {
                className = `${className} bottom-left`;
            }
        } else {
            if (yellow.x === 'right') {
                className = `${className} top-right`;
            } else {
                className = `${className} top-left`;
            }
        }
    
        if (yellow.orientation === 'vertical') {
            className = `${className}-ver`;
        } else {
            className = `${className}-hor`;
        }
    }

    return className;
}

const ParallaxImage = ({ image, decorators, className, wrapperClassName }) => {
    return (
        <div className={`parallax-image ${wrapperClassName ? wrapperClassName : ''} column`}>
            <div className={`ratio ${className} ${decorators && decorators.yellow && decoratorYellowClassName(decorators.yellow)}`}>
                <div className="content">
                    <div className="parallax-wrapper">
                        {decorators && decorators.blue && <div className={decoratorBlueClassName(decorators.blue)} />}
                        <Parallax
                            className="parallax-window"
                            offsetYMax={13}
                            offsetYMin={-19}
                            slowerScrollRate={true}
                        >
                            <LazyBg className="asset-container" src={image} />
                        </Parallax>
                    </div>
                </div>
            </div>
        </div>
    );
};

ParallaxImage.propTypes = {
    wrapperClassName: PropTypes.string,
    className: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    decorators: PropTypes.shape({
        blue: PropTypes.shape({
            x: PropTypes.oneOf([ 'left', 'right' ]).isRequired,
            size: PropTypes.oneOf([ 'S', 'M', 'L' ]).isRequired,
        }),
        yellow: PropTypes.shape({
            x: PropTypes.oneOf([ 'left', 'right' ]).isRequired,
            y: PropTypes.oneOf([ 'bottom', 'top' ]).isRequired,
            orientation: PropTypes.oneOf([ 'vertical', 'horizontal' ]).isRequired,
        }),
    }),
};

export default ParallaxImage;

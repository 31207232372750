import React from 'react';
import PropTypes from 'prop-types';

import LazyBg from './../LazyImage/LazyBg';

import './ImageSmall.css';

const ImageSmall = ({ image, orientation, className, wrapperClassName }) => {

    return (
        <div className={`image-small column ${wrapperClassName} ${orientation}`}>
            <div className="image-small-wrapper">
                <div className={`ratio ${className}`}>
                    <div className="content">
                        <LazyBg src={image} className="asset-container" />
                    </div>
                </div>
            </div>
        </div>
    );
};

ImageSmall.propTypes = {
    wrapperClassName: PropTypes.string,
    className: PropTypes.string.isRequired,
    orientation: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
};

export default ImageSmall;

import points from './polygonPoints';
import moment from 'moment';

export function utcDateToFormat(date, format) {
    if (!moment.utc(date).isValid()) return null;

    return moment.utc(date).format(format);
}

export function kabobToUpperCase(string) {
    if (!string) return null;

    return string.replace(/-/, ' ').toUpperCase();
}

export function formatNumber(number) {
    if (!number) return null;

    // convert to string and add commas
    if (typeof number === 'string') {
        return number.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
  
    return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function formatPhoneNumber(number) {
    if (!number) return null;
  
    // convert to string and format
    if (typeof number === 'string') {
        return number.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }
  
    return number.toString().replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
}

export function getPolygonPoints(hasSmallImage, orientation) {
    let pointsTop, pointsBottom;

    if (hasSmallImage) {
        if (orientation === 'right') {
            // desktop-left
            pointsTop = points.top.small.left;
            pointsBottom = points.bottom.wide.right;
        } else if (orientation === 'left') {
            // desktop-left
            pointsTop = points.top.large.right;
            pointsBottom = points.bottom.wide.left;
        } else if (orientation === 'odd') {
            // mobile-odd
            pointsTop = points.top.small.left;
            pointsBottom = points.bottom.wide.right;
        } else {
            // mobile-even
            pointsTop = points.top.small.right;
            pointsBottom = points.bottom.medium.left;
        }
    } else {
        if (orientation === 'right') {
            // desktop-right
            pointsTop = points.top.small.left;
            pointsBottom = points.bottom.narrow.right;
        } else if (orientation === 'left') {
            // desktop-left
            pointsTop = points.top.small.right;
            pointsBottom = points.bottom.narrow.left;
        } else if (orientation === 'odd') {
            // mobile-odd
            pointsTop = points.top.small.left;
            pointsBottom = points.bottom.medium.right;
        } else {
            // mobile-even
            pointsTop = points.top.small.right;
            pointsBottom = points.bottom.medium.left;
        }
    }

    return { pointsTop, pointsBottom };
}

export function groupBy(arr, prop) {
    if (!arr || !Array.isArray(arr)) return null;
    if (!prop || typeof prop !== 'string') return null;

    return arr.reduce((groups, currVal) => {
        const val = currVal[prop];
        
        groups[val] = groups[val] || [];

        groups[val].push(currVal);
        return groups;
    }, {});
}

export function createMarkup(string) {
    if (!string) return null;
    
    return { __html: string };
}

/* istanbul ignore file */
import React from 'react';

const Placeholder = () => (
    <section className="hero placeholder">
        <div className="loader"></div>
    </section>
);

export default Placeholder;


export default {
    top: {
        small: {
            left: '0,0 40,0 0,25',
            right: '100,0 60,0 100,25',
        },
        large: {
            left: '0,60 0,100 100,100',
            right: '100,0 2,0 100,53',
        },
    },
    bottom: {
        wide: {
            left: '0,60 0,100 100,100',
            right: '100,60 0,100 100,100',
        },
        narrow: {
            left: '0,40 0,100 40,100',
            right: '100,40 60,100 100,100',
        },
        medium: {
            left: '0,60 0,100 60,100',
            right: '100,60 40,100 100,100',
        },
    },
    hero: {
        top: '100,2 30,100 100,100',
        bottom: '100,0 28,100 100,100',
    },
};

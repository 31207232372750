import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import LazyBg from '../LazyImage/LazyBg';

import './PreviewCard.css';

class PreviewCard extends PureComponent {

    render() {
        const { backgroundLocal, backgroundImage, buttonLink, buttonTitle, title } = this.props;
        const image = (backgroundLocal && backgroundLocal === true) ? backgroundImage : `${process.env.RAZZLE_ASSET_URL}${backgroundImage}`;

        return (
            <Link to={buttonLink} className="preview-card">
                <LazyBg src={image} className="card-image-thumb" />
                <div className="card-title">{title}</div>
                <div className="card-cta">{buttonTitle}</div>
            </Link>
        );
    }
}

PreviewCard.propTypes = {
    backgroundLocal: PropTypes.bool,
    backgroundImage: PropTypes.string.isRequired,
    buttonLink: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    buttonTitle: PropTypes.string.isRequired,
};

export default PreviewCard;

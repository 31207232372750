import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ScrollToTop from './ScrollToTop';
import Routes from './Routes';
import Helmet from './Helmet';
import Global from '@modop/hpp-corp-global';
import Placeholder from '../UI/Placeholder';
//import CookieConsent from '../UI/CookieConsent/CookieConsent';
import { setScreenWidth } from '../../store/actions';
import { ParallaxProvider } from 'react-scroll-parallax';

import './App.css';

// Do not use BrowserRouter here, see /src/client/index.js


class App extends PureComponent {
    componentDidMount() {
        if (typeof window === 'object') {
            if (this.props.screen && this.props.screen.width === 0) {
                this.getScreenWidth();
                window.addEventListener('resize', this.getScreenWidth);
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.getScreenWidth);
    }

    getScreenWidth = () => {
        this.props.setScreenWidth(window.innerWidth);
    }

    render() {
        const { loading, errors, screen, location, serverURL } = this.props;
        const url = `${serverURL}${location.pathname}`;

        const Header = Global.header;
        const Footer = Global.footer;

        return (
            <ParallaxProvider>
                <Helmet url={url} />
                <ScrollToTop>
                    <Header
                        location={location}
                        isDesktop={screen.isDesktop}
                        isActive={true}
                        showSubMenu={true}
                    />
                    {loading
                        ? <Placeholder />
                        : errors.length > 0
                            ? <Placeholder text="Error" />
                            : <div className="hpp-corp-container"><Routes serverURL={url} /></div>
                    }
                    <Footer />
                </ScrollToTop>
            </ParallaxProvider>
        );
    }
}

App.propTypes = {
    screen: PropTypes.shape({
        width: PropTypes.number.isRequired,
        isDesktop: PropTypes.bool.isRequired,
    }).isRequired,
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired,
    serverURL: PropTypes.string,
    setScreenWidth: PropTypes.func.isRequired,
};

export default withRouter(
    connect(
        ({ screen, loading, errors }) => ({ screen, loading, errors }),
        { setScreenWidth }
    )(App)
);
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CaseStudyText from './CaseStudyText';
import CaseStudyImages from './CaseStudyImages';
import './CaseStudy.css';

const CaseStudy = ({ caseStudy, orientation, index }) => {
    const {
        title,
        summary,
        images,
        cta,
        type,
    } = caseStudy;

    return (
        <div className={`case-study-${index+1} case-study-container ${images.length > 1 && 'multiple-images'}`}>
            <div className="columns vertical-align is-mobile is-multiline">
                {orientation === 'left' ?
                    <Fragment>
                        <div className={`column is-12-mobile is-6-tablet mobile-order-1 ${images.length > 1 ? 'is-6-desktop' : 'is-5-desktop'}`}>
                            <CaseStudyImages index={index} orientation={orientation} images={images}/>
                        </div>
                        <div className={`column is-12-mobile is-6-tablet mobile-order-2 ${images.length > 1 ? 'is-offset-1-desktop is-5-desktop' : 'is-offset-1-desktop is-6-desktop'}`}>
                            <CaseStudyText
                                orientation={orientation}
                                type={type}
                                title={title}
                                summary={summary}
                                cta={cta}/>
                        </div>
                    </Fragment>
                    :
                    <Fragment>
                        <div className={`column is-12-mobile is-6-tablet mobile-order-2 ${images.length > 1 ? 'is-offset-1-desktop is-5-desktop' : 'is-offset-1-desktop is-6-desktop'}`}>
                            <CaseStudyText
                                orientation={orientation}
                                type={type}
                                title={title}
                                summary={summary}
                                cta={cta}/>
                        </div>
                        <div className={`column is-12-mobile is-6-tablet mobile-order-1 ${images.length > 1 ? 'is-6-desktop' : 'is-5-desktop'}`}>
                            <CaseStudyImages index={index} orientation={orientation} images={images}/>
                        </div>
                    </Fragment>
                }
            </div>
        </div>
    );
};

CaseStudy.propTypes = {
    index: PropTypes.number,
    caseStudy: PropTypes
        .shape({
            title: PropTypes.string.isRequired,
            summary: PropTypes.string.isRequired,
            images: PropTypes.array.isRequired,
            cta: PropTypes.string.isRequired,
            order: PropTypes.number.isRequired,
            type: PropTypes.string.isRequired,
        })
        .isRequired,
    orientation: PropTypes.string.isRequired,
};

export default CaseStudy;

import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import moment from 'moment';
import PreviewCard from '../../../UI/PreviewCard/PreviewCard';
import './News.css';

const News = ({ news }) => {
    if (!news) return null;

    const settings = {
        arrows: false,
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    arrows: true,
                    dots: false,
                    infinite: false,
                    slidesToScroll: 1,
                    slidesToShow: 1,
                    speed: 500,
                },
            },
        ],
    };

    return (
        <section className="section news-section">
            <div className="container">
                <div className="columns">
                    <div className="column is-12-tablet is-offset-1-desktop is-10-desktop">
                        <div className="columns">
                            <div className="column">
                                <div className="pre-title">News</div>
                                <div className="big-title">Press & Insights</div>
                            </div>
                        </div>
                        <div className="columns is-gapless">
                            <Slider {...settings} >
                                {news.map((post) => {
                                    return {
                                        ...post,
                                        published: moment(post.publishedDate),
                                    };
                                }).sort(((a, b) => b.published.format('X') - a.published.format('X'))).slice(0, 3).map((panel, i) =>
                                    <div key={i}>
                                        <div className="column">
                                            <PreviewCard
                                                title={panel.title}
                                                backgroundImage={panel.thumbnail}
                                                buttonLink={`/news${panel.slug}`}
                                                buttonTitle="Read More"
                                            />
                                        </div>
                                    </div>
                                )}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

News.propTypes = {
    news: PropTypes.array,
};

export default News;

// App
export const SET_SCREEN_WIDTH = 'SET_SCREEN_WIDTH';

// API
export const FETCH_CONTENT_REQUEST = 'FETCH_CONTENT_REQUEST';
export const FETCH_CONTENT_SUCCESS = 'FETCH_CONTENT_SUCCESS';
export const FETCH_CONTENT_FAILURE = 'FETCH_CONTENT_FAILURE';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

// Office
export const OFFICE_INIT = 'OFFICE_INIT';
export const OFFICE_KEYWORD = 'OFFICE_KEYWORD';
export const OFFICE_FILTER = 'OFFICE_FILTER';
export const OFFICE_MAP_SEARCH = 'OFFICE_MAP_SEARCH';
export const OFFICE_MAP_CHANGE = 'OFFICE_MAP_CHANGE';
export const OFFICE_RESET = 'OFFICE_RESET';

// Pagination
export const CURRENT_PAGINATION_PAGE = 'CURRENT_PAGINATION_PAGE';

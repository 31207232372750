import { combineReducers } from 'redux';
import {
    screen,
    loading,
    content,
    errors,
    office,
    pagination,
} from './reducers';

const rootReducer = combineReducers({
    screen,
    loading,
    content,
    errors,
    office,
    pagination,
});

export default rootReducer;

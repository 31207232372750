/* istanbul ignore file */
import '@babel/polyfill';
import 'intersection-observer';
import '../common/sass/index.css';
import { loadableReady } from '@loadable/component';
import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import configureStore from '../common/store/configureStore';
import App from '../common/components/App/App';
import { CookiesProvider } from 'react-cookie';

// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = window.__PRELOADED_STATE__;

const serverURL = `${window.location.protocol}//${window.location.host}`;

// Allow the passed state to be garbage-collected
delete window.__PRELOADED_STATE__;

// Create a new Redux store with initial state
const store = configureStore(preloadedState);

loadableReady(() => {
    hydrate(
        <Provider store={store}>
            <CookiesProvider>
                <BrowserRouter>
                    <App serverURL={serverURL} />
                </BrowserRouter>
            </CookiesProvider>
        </Provider>,
        document.getElementById('root')
    );
});

if (module.hot) {
    module.hot.accept();
}

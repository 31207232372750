import routes from './routes';
import request from './request';
import { matchPath } from 'react-router';
import { initialState } from '../store/reducers';

/* Obtain the matching router using the matchPath of reactjs */
const getRouter = (fullpath) => {
    /* Removing last slash(/) in the path */
    const path = fullpath && (fullpath.endsWith('/') && fullpath !== '/')
        ? fullpath.slice(0, -1)
        : fullpath;
    const arrayData = [];

    Object.keys(routes).forEach((name) => {
        arrayData.push(matchPath(path, {
            path: name,
            exact: true,
            strict: true,
        }) || {});
    });

    return arrayData.find((elem) => elem.isExact);
};

/*Replace params in the endpoint api if is necessary*/
const replaceParams = (path, params) => {
    let fullPath = path;

    Object.entries(params).forEach(([ key, value ]) => {
        fullPath = fullPath.replace(`:${key}`, value);
    });
    
    return fullPath;
};

export default function(resolvePath) {
    const routerMatch = getRouter(resolvePath);

    if (!routerMatch) {
        return Promise.resolve({
            loading: false,
            errors: [],
            content: initialState.content,
        });
    }
    
    const router = routes[routerMatch.path];
    const promises = router.map(route => {
        const finalPath = replaceParams(route.path, routerMatch.params);

        return request(finalPath).then(result => result);
    });

    // Request data for each api route
    return Promise.all(promises)
        .then(results => {
            let content = initialState.content;
            let errors = [];

            router.forEach((route, index) => {
                if (results[index].error) {
                    errors.push(results[index]);
                } else {
                    content[route.reducer] = results[index];
                }
            });

            return {
                loading: false,
                errors,
                content,
            };
        })
        .catch(error => error);

}

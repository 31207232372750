import React from 'react';
import './HeroBottom.css';
import iconNareit from './images/logo-nareit.svg';
import iconNaiot from './images/logo-naiop.svg';
import iconCpe from './images/logo-cpe.svg';
import iconGlobeSt from './images/logo-globest.svg';
import iconNewsweek from './images/logo-newsweek.svg';
import iconUrban from './images/logo-urban.svg';

const HeroBottom = () => {

    return (
        <section className="hero-bottom hero-triangle">
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-multiline">
                        <div className="column is-offset-1-tablet is-10-tablet is-11-desktop is-10-widescreen">
                            <div className="title-wrapper animated fadeInLeft">
                                <h1 className="hidden">Hudson Pacific Properties Overview</h1>
                                <div className="pre-title">RECOGNITION</div>
                                <div className="big-title">HIGHLIGHTED AWARDS</div>
                            </div>
                        </div>
                        <div className="column is-offset-1-tablet is-10-tablet is-11-desktop is-10-widescreen">
                            <div className="columns is-mobile is-multiline">
                                <div className="column is-half-mobile is-4-tablet is-4-desktop">
                                    <div className="internal-wrapper icon1">
                                        <div className="iconix">
                                            <img src={iconNaiot} alt={iconNaiot} width="213" height="95" />
                                        </div>
                                        <div className='line'></div>
                                        <div className="bodyCopy">
                                            <span>Developer of the Year</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="column is-half-mobile is-4-tablet is-4-desktop">
                                    <div className="internal-wrapper icon2">
                                        <div className="iconix">
                                            <img src={iconGlobeSt} alt={iconGlobeSt} width="272" height="72" />
                                        </div>
                                        <div className='line'></div>
                                        <div className="bodyCopy">
                                            <span>Best Places to Work</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="column is-half-mobile is-4-tablet is-4-desktop">
                                    <div className="internal-wrapper icon3">
                                        <div className="iconix">
                                            <img src={iconNewsweek} alt={iconNewsweek} width="351" height="52" />
                                        </div>
                                        <div className='line'></div>
                                        <div className="bodyCopy">
                                            <span>America’s Most Responsible Companies</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="column is-half-mobile is-4-tablet is-4-desktop">
                                    <div className="internal-wrapper icon4">
                                        <div className="iconix">
                                            <img src={iconUrban} alt={iconUrban} width="239" height="74" />
                                        </div>
                                        <div className='line'></div>
                                        <div className="bodyCopy">
                                            <span>Award for Excellence</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="column is-half-mobile is-4-tablet is-4-desktop">
                                    <div className="internal-wrapper icon5">
                                        <div className="iconix">
                                            <img src={iconNareit} alt={iconNareit} width="235" height="105" />
                                        </div>
                                        <div className='line'></div>
                                        <div className="bodyCopy">
                                            <span>Leader in the Light</span>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="column is-half-mobile is-4-tablet is-4-desktop">
                                    <div className="internal-wrapper icon6">
                                        <div className="iconix">
                                            <img src={iconCpe} alt={iconCpe} width="136" height="106" />
                                        </div>
                                        <div className='line'></div>
                                        <div className="bodyCopy">
                                            <span>Distinguished Achievement Award</span>
                                        </div>
                                    </div>
                                </div>
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HeroBottom;

/* istanbul ignore next */

/*
    These are the API endpoints that will be to be requested for each page.
    Each unique reducer will need it's initial state defined in common/store/reducers.js
*/

export default {
    '/': [
        { reducer: 'caseStudies', path: '/casestudy' },
        { reducer: 'homepageCareer', path: '/homepageCareer' },
        { reducer: 'news', path: '/news' },
    ],
    '/about': [
        //{ reducer: 'timelineEvents', path: '/timeline' },
        // { reducer: 'caseStudies', path: '/casestudy' },
    ],
    '/careers': [
        { reducer: 'homepageCareer', path: '/homepageCareer' },
        { reducer: 'people', path: '/people' },
    ],
    '/careers/positions': [
        { reducer: 'jobs', path: '/job' },
    ],
    '/careers/positions/:slug': [
        { reducer: 'jobs', path: '/job' },
    ],
    '/office': [
        { reducer: 'properties', path: '/property' },
        { reducer: 'collection', path: '/collection' },
    ],
    '/office/list': [
        { reducer: 'properties', path: '/property' },
        { reducer: 'collection', path: '/collection' },
    ],
    '/office/map': [
        { reducer: 'properties', path: '/property' },
        { reducer: 'collection', path: '/collection' },
    ],
    '/office/:slug': [
        { reducer: 'properties', path: '/property' },
        { reducer: 'collection', path: '/collection' },
    ],
    '/collection/:slug': [
        { reducer: 'properties', path: '/property' },
        { reducer: 'collection', path: '/collection' },
    ],
    '/news/:slug': [
        { reducer: 'news', path: '/news' },
    ],
    '/development': [
        { reducer: '', path: '/development' },
        { reducer: 'caseStudies', path: '/casestudy' },
    ],
    '/redevelopment': [
        { reducer: '', path: '/redevelopment' },
        { reducer: 'caseStudies', path: '/casestudy' },
    ],
    '/leasing': [
        { reducer: '', path: '/leasing' },
        { reducer: 'caseStudies', path: '/casestudy' },
    ],
    '/operations': [
        { reducer: '', path: '/operations' },
        { reducer: 'caseStudies', path: '/casestudy' },
    ],
    '/news': [
        { reducer: 'news', path: '/news' },
    ],
    '/studio': [
        { reducer: 'studios', path: '/studio' },
        { reducer: 'studioCTA', path: '/studioCTA' },
        { reducer: 'quixote', path: '/quixote' },
    ],
    '/responsibility': [
        { reducer: 'responsibility', path: '/responsibility' },
    ],
};

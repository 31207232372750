import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';

import './LazyBg.css';

class LazyImg extends Component {
    static defaultProps = {
        critical: false,
        className: '',
    };
    state = {
        isLoaded: false,
    };
    constructor(props) {
        super(props);
        this.el = createRef();
    }
    componentDidMount() {
        if (!this.props.critical) {
            this.observer = new IntersectionObserver(this.handleIntersection, {
                threshold: 0.25,
            });
            this.observer.observe(this.el.current);
            this.image = new Image();
            this.image.addEventListener('load', this.handleImageLoaded);
            window.addEventListener('load', () => {
                setTimeout(() => {
                    this.triggerLoading();
                }, 900);
            });
        }
    }
    componentWillUnmount() {
        this.handleCleanup();
    }
    handleIntersection = intersection => {
        const [ entry ] = intersection;

        if (entry.intersectionRatio > 0) {
            this.triggerLoading();
        }
    };
    triggerLoading = () => {
        this.image.src = this.props.src;
        try {
            this.observer.unobserve(this.el.current);
        } catch (e) {
            // The following console log is an exception to eslint since we don't have error report in place
            console.log(e); // eslint-disable-line
        }
    };
    handleCleanup = () => {
        if (!this.props.critical) {
            try {
                this.observer.unobserve(this.el.current);
            } catch (e) {
                // The following console log is an exception to eslint since we don't have error report in place
                console.log(e); // eslint-disable-line
            }
            window.removeEventListener('load', this.triggerLoading);
        }
    };
    handleImageLoaded = () => {
        this.setState({
            isLoaded: true,
        });
    };
    render() {
        const { critical, className, src } = this.props;
        const { isLoaded } = this.state;

        if (critical || isLoaded) {
            return (
                <div
                    className={className}
                    style={{
                        backgroundImage: `url("${src}")`,
                    }}
                ></div>
            );
        }

        return (
            <div className={`lazybg-placeholder ${className}`} ref={this.el}>
                <div className="placeholder-content">
                    <div className="loader"></div>
                </div>
            </div>
        );
    }
}

LazyImg.propTypes = {
    src: PropTypes.string.isRequired,
    critical: PropTypes.bool,
    className: PropTypes.string,
};

export default LazyImg;

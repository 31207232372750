import contentApi from '../api/content';
import {
    SET_SCREEN_WIDTH,
    CURRENT_PAGINATION_PAGE,
    FETCH_CONTENT_REQUEST,
    FETCH_CONTENT_SUCCESS,
    FETCH_CONTENT_FAILURE,
    CLEAR_ERRORS,
    OFFICE_INIT,
    OFFICE_KEYWORD,
    OFFICE_FILTER,
    OFFICE_MAP_SEARCH,
    OFFICE_MAP_CHANGE,
    OFFICE_RESET,
} from './constants';

/***** Action Creators *****/

export const fetchContentRequest = path => ({
    type: FETCH_CONTENT_REQUEST,
    payload: path,
});

export const fetchContentSuccess = content => ({
    type: FETCH_CONTENT_SUCCESS,
    payload: content,
});

export const fetchContentFailure = errors => ({
    type: FETCH_CONTENT_FAILURE,
    payload: errors,
});

export const clearErrors = () => ({
    type: CLEAR_ERRORS,
    payload: null,
});

export const setScreenWidth = width => ({
    type: SET_SCREEN_WIDTH,
    payload: width ? width : 0,
});

export const currentPaginationPage = page => ({
    type: CURRENT_PAGINATION_PAGE,
    payload: page ? page : 1,
});

export const office = {
    initPage: payload => ({ type: OFFICE_INIT, payload }),
    applyFilter: payload => ({ type: OFFICE_FILTER, payload }),
    keywordSearch: payload => ({ type: OFFICE_KEYWORD, payload }),
    mapSearch: payload => ({ type: OFFICE_MAP_SEARCH, payload }),
    mapChange: payload => ({ type: OFFICE_MAP_CHANGE, payload }),
    reset: payload => ({ type: OFFICE_RESET, payload }),
};

/***** Async Action Creators *****/

export const fetchContent = path => {
    
    return dispatch => {
        dispatch(fetchContentRequest(path));

        return contentApi(path)
            .then(content => dispatch(fetchContentSuccess(content)))
            .catch(error => dispatch(fetchContentFailure(error)));
    };
};

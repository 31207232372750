import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import CaseStudies from './CaseStudies/CaseStudies';
import Hero from './Hero/Hero';
import HeroBottom from './HeroBottom/HeroBottom';
import News from './News/News';


export const Home = ({ isDesktop, screenData, caseStudies, news }) => (
    
    <div className="home">
        <Helmet>
            <title>Hudson Pacific Properties: A West Coast Real Estate Group</title>
            <link rel="icon" type="image/x-icon" href="/favicon.ico" />
            <meta name="description" property="og:description" content="Hudson Pacific Properties acquires, redevelops and develops creative office and studio properties in the West Coast tech and media epicenters of Los Angeles, Silicon Valley, San Francisco, Seattle and Vancouver B.C." />
        </Helmet>
        <Hero screenData={screenData} />
        {caseStudies && <CaseStudies isDesktop={isDesktop} caseStudies={caseStudies} />}
        <HeroBottom />
        {news && <News news={news} />}
    </div>
);

Home.propTypes = {
    isDesktop: PropTypes.bool,
    screenData: PropTypes.array,
    caseStudies: PropTypes.array,
    news: PropTypes.array,
};

export default connect(
    ({ screen, content }) => ({
        isDesktop: screen.isDesktop,
        screenData: screen,
        caseStudies: content.caseStudies && content.caseStudies.length > 0
            ? content.caseStudies
                .filter(caseStudy => caseStudy.featured === true)
                .sort((a, b) => a.order > b.order)
            : null,
        news: content.news,
    }),
    null
)(Home);

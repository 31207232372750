import React from 'react';
import PropTypes from 'prop-types';
import ScrollAnimation from 'react-animate-on-scroll';
import { createMarkup } from '../../../../utils/helpers';
import 'animate.css/animate.min.css';
import '../../../UI/Typography/Typography.css';

const CaseStudyText = ({ title, type, summary, cta, orientation }) => {
    const animateIn = orientation === 'left' ? 'fadeInRight' : 'fadeInLeft';

    return (
        <div className="text-container">
            <div className="text-content">
                {title && <ScrollAnimation
                    duration={0.65}
                    animateOnce={true}
                    animateIn={animateIn}
                    delay={75}
                    initiallyVisible={false}>
                    <div className="border-left-yellow">
                        <div className="big-title font-ff-din-pro">
                            <span dangerouslySetInnerHTML={createMarkup(title)} />
                        </div>
                    </div>
                </ScrollAnimation>}
            </div>
        </div>
    );
};

CaseStudyText.propTypes = {
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    cta: PropTypes.string.isRequired,
    orientation: PropTypes.string.isRequired,
};

export default CaseStudyText;

import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from './rootReducer';

const configureStore = preloadedState => {
    let middlewares;
    const showLogs = process.env.RAZZLE_REDUX_LOGGER || 'on';

    // Do not show console logs in production environment
    /* istanbul ignore next */
    if (process.env.NODE_ENV === 'PRODUCTION' || showLogs === 'off') middlewares = [ thunk ];
    else middlewares = [ thunk, logger ]; // logger must be last

    /*
        WARNING: Redux DevTools Extension are unstable.
        If the app crashes randomly, turn remove dev tools.
    */
    const composeEnhancers =
        typeof window === 'object' &&
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
            }) : compose;

    const enhancer = composeEnhancers(
        applyMiddleware(...middlewares),
        // other store enhancers if any
    );
    
    const store = createStore(
        rootReducer,
        preloadedState,
        enhancer,
    );

    /* istanbul ignore if */
    if (module.hot) {
    // Enable Webpack hot module replacement for reducers
        module.hot.accept('./rootReducer', () => {
            const nextRootReducer = require('./rootReducer').default;

            store.replaceReducer(nextRootReducer);
        });
    }
  
    return store;
};

export default configureStore;

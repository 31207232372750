import React from 'react';
import './Background.css';

const Background = () => (
    <div className="background-container">

        <div className="triangle-1"></div>
        {/* TODO */}

    </div>
);

export default Background;

import React from 'react';
import PropTypes from 'prop-types';
import Background from './Background';
import CaseStudy from '../CaseStudy/CaseStudy';

const CaseStudies = ({ isDesktop = true, caseStudies }) => {
    if (isDesktop === undefined || !caseStudies) return null;

    return (
        <section className="section case-studies-container">
            {isDesktop && <Background />}
            <div className="container">
                {caseStudies.map((caseStudy, i) => {
                    return <CaseStudy
                        key={i}
                        index={i}
                        orientation={i % 2 === 0
                            ? 'right'
                            : 'left'}
                        caseStudy={caseStudy} />;
                })}
            </div>
        </section>
    );
};

CaseStudies.propTypes = {
    isDesktop: PropTypes.bool,
    caseStudies: PropTypes.array.isRequired,
};

export default CaseStudies;

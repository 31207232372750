import {
    SET_SCREEN_WIDTH,
    CURRENT_PAGINATION_PAGE,
    FETCH_CONTENT_REQUEST,
    FETCH_CONTENT_SUCCESS,
    FETCH_CONTENT_FAILURE,
    CLEAR_ERRORS,
    OFFICE_INIT,
    OFFICE_KEYWORD,
    OFFICE_FILTER,
    OFFICE_MAP_SEARCH,
    OFFICE_MAP_CHANGE,
    OFFICE_RESET,
} from './constants';

export const initialState = {
    loading: false,
    errors: [],
    // Must provide initial mobile first values for SSR
    screen: {
        width: 0,
        isMobile: false,
        isTablet: false,
        isDesktop: false,
    },
    pagination: {
        page: 1,
    },
    // Must include all unique reducers in common/api/routes.js
    content: {
        caseStudies: null,
        homepageCareers: null,
        homes: null,
        jobs: null,
        news: null,
        people: null,
        properties: null,
        timelineEvents: null,
        studios: null,
        studioCTA: null,
        quixote: null,
        responsibility: null,
    },
    office: {
        visibleProperties: [], // all properties or search/filter results
        totalText: '', // total visibleProperties and total sq ft
        keyword: '', // search input value
        filters: {
            region: null,
            subMarket: null,
            projectType: null,
            totalSqFt: null,
            availableSqFt: null,
        },
        options: {
            regions: [],
            subMarkets: [],
            projectTypes: [],
            totalSqFts: [],
        },
        maxSqFts: 10000, // max amount of sq ft available
        map: {
            zoom: 5, // default zoom
            center: {
                lat: 40.7886, lng: -119.203, // default center
            },
            propertyId: '', // selected id from list or marker
            infoWindowId: '',
        },
    },
};

export function loading(state = initialState.loading, { type, payload }) {
    switch (type) {
        case FETCH_CONTENT_REQUEST:
            return true;
        case FETCH_CONTENT_SUCCESS:
        case FETCH_CONTENT_FAILURE:
            return false;
        default:
            return state;
    }
}

export function errors(state = initialState.errors, { type, payload }) {
    switch (type) {
        case FETCH_CONTENT_FAILURE:
            return payload;
        case FETCH_CONTENT_SUCCESS: {
            if (payload.errors) return [ ...state, ...payload.errors ];
            else return [];
        }
        case CLEAR_ERRORS:
            return [];
        default:
            return state;
    }
}

export function content(state = initialState.content, { type, payload }) {
    switch (type) {
        case FETCH_CONTENT_SUCCESS:
            return { ...state, ...payload.content };
        case FETCH_CONTENT_FAILURE:
        default:
            return state;
    }
}

export function screen(state = initialState.screen, { type, payload }) {
    switch (type) {
        case SET_SCREEN_WIDTH:
            return {
                width: payload,
                isMobile: payload <= 767,
                isTablet: payload > 767 && payload < 1024,
                isDesktop: payload >= 1024,
            };
        default:
            return state;
    }
}

export function pagination(state = initialState.pagination, { type, payload }) {
    switch (type) {
        case CURRENT_PAGINATION_PAGE:
            return {
                page: payload,
            };
        default:
            return state;
    }
}

export function office(state = initialState.office, { type, payload }) {
    switch (type) {
        case OFFICE_INIT:
            return {
                ...state,
                visibleProperties: payload.visibleProperties,
                totalText: payload.totalText,
                options: payload.options,
                maxSqFts: payload.maxSqFts,
            };
        case OFFICE_KEYWORD:
            return {
                ...state,
                visibleProperties: payload.visibleProperties,
                totalText: payload.totalText,
                options: payload.options,
                keyword: payload.keyword,
            };
        case OFFICE_FILTER:
            return {
                ...state,
                visibleProperties: payload.visibleProperties,
                totalText: payload.totalText,
                options: payload.options,
                filters: payload.filters,
            };
        case OFFICE_MAP_SEARCH:
            return {
                ...state,
                visibleProperties: payload.visibleProperties,
                totalText: payload.totalText,
                // options: payload.options,
                options: {
                    ...state.options,
                    ...payload.availableOptions,
                },
                map: payload.map,
            };
        case OFFICE_MAP_CHANGE:
            return {
                ...state,
                map: {
                    ...state.map,
                    propertyId: payload.propertyId,
                    infoWindowId: payload.infoWindowId,
                },
            };
        case OFFICE_RESET:
            return {
                visibleProperties: payload.visibleProperties,
                totalText: payload.totalText,
                keyword: initialState.office.keyword,
                filters: {
                    region: null,
                    subMarket: null,
                    projectType: null,
                    totalSqFt: null,
                    availableSqFt: null,
                },
                options: payload.options,
                map: initialState.office.map,
                maxSqFts: state.maxSqFts,
            };
        default:
            return state;
    }
}


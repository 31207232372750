import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
        const pathsNoScroll = [ '/office/', '/office/list', '/office/list/', '/office/map', '/office/map/' ];
        
        if (this.props.location !== prevProps.location && !pathsNoScroll.includes(this.props.location.pathname)) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        return this.props.children;
    }
}

ScrollToTop.propTypes = {
    location: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]).isRequired,
};

export default withRouter(ScrollToTop);

/* istanbul ignore file */
import React from 'react';
import { Helmet } from 'react-helmet';
import './NotFound.css';

const Contact = () => (
    <div className="not-found hero">
        <Helmet>
            <title>Hudson Pacific Properties: A West Coast Real Estate Group</title>
            <meta name="description" property="og:description" content="404 OOPS...Something went wrong" />
        </Helmet>
        <div className="hero-body">
            <div className="container">
                <div className="columns">
                    <div className="column is-offset-1-tablet is-10-tablet">
                        <div className="pre-title">
                            404
                        </div>
                        <h1 className="big-title">OOPS...Something<br /> went wrong</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default Contact;

/* istanbul ignore file */
import superagent from 'superagent';

export default function(path) {
    const url = `${process.env.RAZZLE_API_URL}/api/v1${path}`;
    
    return superagent
        .get(url)
        .then(res => res.body)
        .catch(err => {
            return { error: err.message, path };
        });
}

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import VideoBackground from './VideoBackground';

import './Hero.css';

const Hero = screenData => {
    return (
        <section className="hero">
            <VideoBackground screenData={screenData} />

            <div className="hero-body">
                {/* <div className="container"> */}
                <div className="hero-container">
                    <div className="columns is-gapless is-mobile hero-maxwidth">
                        <div className="column is-12-mobile is-12-tablet">
                            <div className="upper-block-wrapper">
                                <div className="title-wrapper animated fadeInLeft">
                                    <h1 className="pre-title">HUDSON PACIFIC PROPERTIES</h1>
                                    <div className="big-title">
                                        Reimagining now.
                                        <br />
                                        To create <br className="is-hidden-mobile" /> what’s next.
                                    </div>
                                </div>
                                <div className="columns">
                                    <div className="column is-12-tablet is-10-desktop is-8-widescreen hero-buttons-container">
                                        <div className="cta-wrapper">
                                            <Link className="button animated fadeInLeft" to="/office">
                                                <span>Search Our Offices</span>
                                            </Link>
                                            <Link className="button animated fadeIn" to="/studio">
                                                <span>Explore Our Studios</span>
                                            </Link>
                                            <a
                                                className="button animated fadeInRight"
                                                href="http://investors.hudsonpacificproperties.com/investor-relations/company-overview/default.aspx"
                                            >
                                                <span>Invest With Us</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
            <div className="about-us-bottom">
                    <div className="lower-block-wrapper-2 animated fadeInLeft">
                        <div className="pre-title">ABOUT US</div>
                        <div className="summary">
                            We’re a visionary real estate company focused on epicenters of innovation for media and tech. For our investors and partners, for the
                            Fortune 500 and the world’s most disruptive start-ups, we deliver.
                        </div>
                    </div>
                </div>
        </section>
    );
};

Hero.propTypes = {
    screenData: PropTypes.object,
};

export default Hero;
